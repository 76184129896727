(function($) {
  Drupal.behaviors.LBO = {
    attach: function(context, settings) {
      if (typeof $.cookie === "function") {
        var offerInfoCookie = $.cookie("offer_info");
        if (offerInfoCookie !== "" && offerInfoCookie !== null) {
          if (offerInfoCookie.split(":").length < 2) return false;
          var offer_code = offerInfoCookie.split(":")[1];
          generic.jsonrpc.fetch({
            method: "offer.linkedOfferState",
            params: [offer_code],
            onSuccess: function(r) {
              var offer = r.getValue(),
                isActive = (offer.linked_offer !== null && offer.linked_offer.length !== 0),
                templateResult = null;
              if (isActive) {
                var htmlStr = offer.linked_offer.cms_content,
                    $body = $("body", context),
                    $lboTemplate = null,
                    $confirmMessage = null,
                    $lboContainer = null,
                    $pageWrapperElem = null;
                $(htmlStr).appendTo($body);
                $lboTemplate = $body.find(".js-lbo");
                $confirmMessage = $lboTemplate.find(".js-lbo-message-content");
                $confirmMessage.html(offer.linked_offer.offer_message);
                templateResult = $lboTemplate.html();
                $pageWrapperElem = $(".pg_wrapper");

                if (!$pageWrapperElem.hasClass("viewcart") && !$pageWrapperElem.hasClass("onepage")) {
                  $body.append(templateResult);
                  $body.children(".js-lbo-overlay-bg").removeClass("hidden");
                }
                $lboContainer = $body.children(".js-lbo-overlay");
                $lboContainer.find(".js-lbo-wrapper-close").on("click", function(e) {
                  $lboContainer.add(".js-lbo-overlay").remove();
                  $body.children(".js-lbo-overlay-bg").addClass("hidden");
                });
              }
            },
            onFailure: function(r) {}
          });
        }
      }
    }
  };
})(jQuery);
